// Importa las bibliotecas y estilos necesarios
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
import 'bootstrap';
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
import Swal from 'sweetalert2';
window.Swal = Swal;
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.default.css';
import 'selectize';

// Define una función para inicializar el tooltip
function initializeTooltip() {
  $('[data-toggle="tooltip"]').tooltip({
    trigger : 'hover'
  });
}

function initializeMedicalIncome() {
  // Lógica para inicializar medical_income
  const targetDiv = document.querySelector('[data-js="medical_income"]');
  if (targetDiv) {
    import('packs/medical_income');
    console.log('Initializing Medical Income module');
  }
}

function initializeMedicalReincome() {
  // Lógica para inicializar medical_reincome
  const targetDiv = document.querySelector('[data-js="medical_reincome"]');
  if (targetDiv) {
    import('packs/medical_reincome');
    console.log('Initializing Medical Reincome module');
  }  
}

function initializePsychologicalIncome() {
  // Lógica para inicializar psychological_income
  const targetDiv = document.querySelector('[data-js="psychological_income"]');
  if (targetDiv) {
    import('packs/psychological_income');
    console.log('Initializing Psychological Income module');
  }
}

function initializePsychologicalIncomeLeyKarin() {
  // Lógica para inicializar psychological_income
  const targetDiv = document.querySelector('[data-js="psychological_income_ley_karin"]');
  if (targetDiv) {
    import('packs/psychological_income_ley_karin');
    console.log('Initializing Psychological Income Ley Karin module');
  }
}

function initializePsychologicalTreatmentEP() {
  // Lógica para inicializar medical_reincome
  const targetDiv = document.querySelector('[data-js="psychological_treatment_EP"]');
  if (targetDiv) {
    import('packs/psychological_treatment_EP');
    console.log('Initializing psychological_treatment_EPe module');
  }  
}

function initializeMedicalControl() {
  // Lógica para inicializar medical_control
  const targetDiv = document.querySelector('[data-js="medical_control"]');
  if (targetDiv) {
    import('packs/medical_control');
    console.log('Initializing Medical Control module');
  }
}

function initializePsychotherapy() {
  // Lógica para inicializar psychotherapy
  const targetDiv = document.querySelector('[data-js="psychotherapy"]');
  if (targetDiv) {
    import('packs/psychotherapy');
    console.log('Initializing Psychotherapy module');
  }
}

function initializeAdherenceWorkshop() {
  // Lógica para inicializar adherence_workshop
  const targetDiv = document.querySelector('[data-js="adherence_workshop"]');
  if (targetDiv) {
    import('packs/adherence_workshop');
    console.log('Initializing Adherence Workshop module');
  }  
}

function initializeJobCoahing() {
  // Lógica para inicializar job_coaching
  const targetDiv = document.querySelector('[data-js="job_coaching"]');
  if (targetDiv) {
    import('packs/job_coaching');
    console.log('Initializing Job Coaching module');
  }  
}

function initializeGroupCounseling() {
  // Lógica para inicializar group_counseling
  const targetDiv = document.querySelector('[data-js="group_counseling"]');
  if (targetDiv) {
    import('packs/group_counseling');
    console.log('Initializing Group Counseling module');
  }  
}

function initializeJobReinstatement() {
  // Lógica para inicializar job_reinstatement
  const targetDiv = document.querySelector('[data-js="job_reinstatement"]');
  if (targetDiv) {
    import('packs/job_reinstatement');
    console.log('Initializing Job Reinstatement module');
  }  
}

function initializeClinicalBoard() {
  // Lógica para inicializar clinical_board
  const targetDiv = document.querySelector('[data-js="clinical_board"]');
  if (targetDiv) {
    import('packs/clinical_board');
    console.log('Initializing Clinical Board module');
  }  
}

// Define una función para iniciar el temporizador
function startTimer(duration, display) {
  var timer = duration, minutes, seconds;
  var intervalId = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.textContent = minutes + ":" + seconds;

    if (timer <= 0) {
      display.classList.add('negative'); // Añade la clase para texto rojo cuando el temporizador llegue a cero
      clearInterval(intervalId); // Detiene el temporizador
    } else {
      display.classList.remove('negative'); // Asegura que la clase se elimine si el temporizador no es cero
    }

    timer--;
  }, 1000);
}

// Define una función para inicializar Selectize en los selectores con clase 'selectize'
function initializeSelectize() {
  $('.selectize').selectize({
    create: false, // Desactiva la creación de nuevas opciones
    placeholder: 'Buscar',
    allowClear: true // Opcional, para permitir borrar la selección
  });
}

function initializeDoubleClick() {
  $('.avoid-double-click').on('click', function(event) {
    var $link = $(this);
    var $icon = $link.find('i');
    
    if (!$link.hasClass('disabled')) {
      // Guardar el texto original en un atributo data
      var originalText = $icon.text();
      $link.data('original-text', originalText);
      $link.addClass('disabled');
      
      // Cambiar a spinner y texto "Cargando..."
      $icon.html('<i class="fa fa-spinner fa-spin"></i> Cargando...');
      
      event.preventDefault(); // Evita que el evento se propague

      // Redirige manualmente al enlace href después de un ligero retraso
      setTimeout(function() {
        window.location.href = $link.attr('href');
      }, 200); 
    } else {
      event.preventDefault(); // Evitar que el evento se propague si ya está deshabilitado
    }
  });
}

// Restablecer el botón y el texto del ícono al cargar la página
window.addEventListener('pageshow', function() {
  $('.avoid-double-click').each(function() {
    var $link = $(this);
    var $icon = $link.find('i');

    // Restaurar el texto original del ícono desde el atributo data
    var originalText = $link.data('original-text');
    if (originalText) {
      $icon.text(originalText);
    }

    $link.removeClass('disabled'); // Quitar la clase deshabilitada
  });
});

// Documento listo: ejecuta las funciones necesarias
document.addEventListener('turbolinks:load', function () {
  initializeTooltip();
  initializeMedicalIncome();
  initializeMedicalReincome()
  initializePsychologicalIncome();
  initializePsychologicalIncomeLeyKarin();
  initializeMedicalControl();
  initializePsychotherapy();
  initializeAdherenceWorkshop();
  initializeJobCoahing();
  initializeGroupCounseling();
  initializeJobReinstatement();
  initializeClinicalBoard();
  initializePsychologicalTreatmentEP();

  // Inicia el temporizador si existe el elemento con id 'time'
  var display = document.querySelector('#time');
  if (display) {
    startTimer(60 * 60 * 2, display);
  }

  // Inicializa Selectize
  initializeSelectize();
  initializeDoubleClick();

  // Ajusta la posición sticky del contenedor durante la apertura del modal
  $('#modal_patient_treatment').on('show.bs.modal', function () {
    $('#patient-information').css('position', 'static');
  });

  $('#modal_patient_treatment').on('hidden.bs.modal', function () {
    $('#patient-information').css('position', 'sticky');
  });
  
});